<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <admin-form v-if="admin"
                :submit-text="pageTitle"
                :admin="admin"
                :adminGroups="adminGroups"
                :role="role"
                @on-submit="createAdmin">
    </admin-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import AdminForm from '@admin/views/admin/_EditForm'
import AdminService from '@admin/services/AdminService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  components: { PlaceholderForm, AdminForm },
  data () {
    return {
      pageTitle: '新建管理员',
      admin: null,
      adminGroups: [],
      role: []
    }
  },
  async created () {
    const { data } = await flatry(AdminService.create())

    if (data) {
      const param = {
        phone: '',
        password: '',
        passwordRepeat: '',
        avatar: '',
        groupId: '',
        roles: []
      }
      this.adminGroups = data.data.stores
      this.role = data.data.roles
      this.admin = param
      console.log(this.role)
    }
  },

  methods: {
    async createAdmin (admin, success, callback) {
      const { data } = await flatry(AdminService.create(admin))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/admin/update', query: { id: data.data } })

        success()
      }

      callback()
    }
  }
}
</script>
